import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			host: '',
			deviceType: 'desktop',
			// deviceType: 'mobile',
			showDebug: false,
			adsenseConfig: {
				scriptUrl: '',
				ads: 'success',
			},
			firebaseConfig: {
				apiKey: 'AIzaSyB4UwgXrurDMUbwGZuQTIgglU4pejcOoNk',
				authDomain: 'thevergeghi-shop01.firebaseapp.com',
				projectId: 'thevergeghi-shop01',
				storageBucket: 'thevergeghi-shop01.appspot.com',
				messagingSenderId: '7686472466',
				appId: '1:7686472466:web:bc294ec76e2fa6e9e529cb',
				measurementId: 'G-EWMNF9P',
			},
			themeConfig: {
				backgroundColor: '#fff', // 整个页面的背景颜色
				leadType: 2, //2.0版本大卡样式 默认
				collectionType: 13, //小卡样式
				themeBaseColor: '#000', //主要主体色，顶部
				mainColorType: '#FFFA7F', //导航部分主题色
				bigCardType: 1, //大卡样式
				textCardType: 1, //文本卡片样式
				bigCardConfig: [
					{ cardBackground: '#000', contentClass: 'type-1', cardContentBackground: '#64B4FF' }, //大卡配置第一个  cardBackground 对应的背景颜色
					{ cardBackground: '#fff', contentClass: 'type-2', cardContentBackground: '#FF5A5A' },
				],
				textCardConfig: [
					{
						typeName: 'more',
						background: '#FFFA7F',
					},
					{
						typeName: 'hot',
						background: '#7FF7FF',
					},
				],
				landType: 1, //落地页的顶部导航栏样式  新1
				landFeedCollectionType: 1,

				navType: 3, //导航栏默认
				fooType: 1, //底部默认

				adsPos: [], //第几个新闻的上面！
				adsPosPC: [], //第几个新闻的上面！
				landAdPos: [], //0,1,2 内容详情页的顶/中/底，如果中激活时，需要根据landType改变广告位置
			},
			domainConfig: {
				title: 'KY Health',
				icon: 'http://cms-cdn.mywhat.online/prod/task/icon/D831ZhUK.png',
			},
			sideBar: [],
			title: null,
			requestDomain: null,
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setTitle(state, title) {
				state.title = title
			},
			setDomain(state, domainConfig) {
				state.domainConfig = domainConfig
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setAdsense(state, adsenseConfig) {
				state.adsenseConfig = adsenseConfig
			},
			setFirebase(state, firebaseConfig) {
				state.firebaseConfig = firebaseConfig
			},
			setTheme(state, themeConfig) {
				state.themeConfig = themeConfig
			},
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
			saveSideBar(state, data) {
				state.sideBar = data
			},
			setRequestDomain(state, data) {
				state.requestDomain = data
			},
		},
		getters: {
			themeConfig: (state) => state.themeConfig,
			domainConfig: (state) => state.domainConfig,
			showDebug: (state) => state.showDebug,
			deviceType: (state) => state.deviceType,
			host: (state) => state.host,
			adsenseConfig: (state) => state.adsenseConfig,
		},
	})
}
