<template>
  <div id="app">
    <!-- <router-view /> -->
    <keep-alive exclude="landing">
      <router-view :key="$route.path" />
    </keep-alive>
  </div>
</template>

<script>
import '@/css/main.scss'

export default {
  name: "App",
  async mounted () {
    let debug = this.$global.GetQueryString('db')
    if (debug) {
      this.$store.commit('setDebug', true)
    }
    try {
      await this.getSideBar();
    } catch (error) {
      console.error('An error occurred in getSideBar:', error);
    }
  },
  methods: {
    async getSideBar () {
      try {
        const res = await this.$global.server.getSideBar();

        if (res.code != 200) {
          console.log('获取失败');
          return;
        }

        console.log(res.data);
        this.$store.commit('saveSideBar', res.data)

      } catch (err) {
        console.error('An error occurred while getSideBar:', err);
      }
    },
  },
};
</script>
